import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/PageHeader"
import SEO from "../components/seo"
import BlogListing from "../components/blog/BlogListing";
import { graphql } from "gatsby"

const BlogPage = ({data}) => (
  <Layout>
    {console.log(data)}
    <SEO title="Blog" />
    <PageHeader title="Blog" />
    <div id="blog" class="blog-area pt-50 pb-110">
      <div class="container">
        <div class="row">
          {data.wordpress.posts.nodes.map(post => (
            <BlogListing post={post}/>
          ))}
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
query MyQuery {
  wordpress {
    posts {
      nodes {
        title
        PostDescription {
          description
        }
        modified
        date
        excerpt
        uri
      }
    }
  }
}

`

export default BlogPage
