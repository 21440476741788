import React from "react"
import { Link } from "gatsby"
import ParseHTML from "../../particles/parseHTML"

const BlogListing = props => {
  console.log(props.post.uri)
  return (
    <div className="col-xl-6 col-lg-6">
      <div
        className="blog-wrapper blue-blog mb-50 wow fadeInUp"
        data-wow-delay=".3s"
      >
        <div className="blog-thumb mb-25">
          <Link to={props.post.uri}>
            <img src="img/blog/blog1.jpg" alt="" />
          </Link>
        </div>
        <Link to={props.post.uri}>
          <div className="blog-content">
            <h5>
              <Link to={props.post.uri}>{props.post.title}</Link>
            </h5>
            <div className="blog-meta mb-10">
              <span>
                {new Date(props.post.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
              </span>
            </div>
            <p>{ParseHTML(props.post.excerpt)}</p>
          </div>
        </Link>
      </div>
    </div>
  )
}

export default BlogListing
